import CryptoJS from 'crypto-js'

class Encrypt {

  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_DATA_ENCODE_KEY).toString()
  }

  decrypt(data) {
    const bytes  = CryptoJS.AES.decrypt(data, process.env.VUE_APP_DATA_ENCODE_KEY)
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  }

}

export default new Encrypt()

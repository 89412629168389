import 'regenerator-runtime/runtime'

import AppConfig from '@/configs/app.config.js'
import router from '@/router'

import moment from '@/services/moment'

import firebase from 'firebase/app'
import 'firebase/auth'

const getProfile = async () => {
	return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        firebase
        .auth()
        .currentUser
        .getIdToken(true)
        .then(async (idToken)=>{
        	// บันทึก token
          await localStorage.setItem(AppConfig.key.LoggedIn, JSON.stringify({
          	token: idToken,
          	expire: moment().add(50, 'm').format("YYYY-MM-DD HH:mm:ss")
          }))

          resolve({
            fullname: user.displayName,
            username: user.email,
            role: 'Manage',
            img: user.photoURL,
            token: idToken
          })
        })
      } else {
        // No user is signed in.
        console.log('No user is signed in.')
        reject('No user is signed in.')
      }
    });
  });
}

const getToken = () => {
	try {
		let user = localStorage.getItem(AppConfig.key.LoggedIn)

		if(!user) {
			throw null
		}

		user = JSON.parse(user)
		// console.log('user', user)
		if(moment().isAfter(user.expire)) {
			throw null
		}

		return user.token
	}
	catch(e) {
		console.log(e)
		return false
	}
}

class AuthService {

	async getToken() {
		let token = getToken()
		if(token) {
			return token
		}else{
			let user = await getProfile()
			if(user) {
				return user.token
			}else{
				return false
			}
		}
	}

	async getProfile() {
		return getProfile()
	}

	async saveLogin() {
		return getProfile()
	}

	async logout() {
    await localStorage.removeItem(AppConfig.key.LoggedIn)
    await firebase.auth().signOut()
    router.push({name: 'Login'})
  }

}

export default new AuthService()

<template>
  <span>{{text}}</span>
</template>
<script>
import { openBetLabels } from '@/configs/market.config'

export default {
  name: 'NumberType',
  props: ['type'],
  computed: {
    text() {
      return openBetLabels?.[this.type] || this.type
    }
  }
}
</script>

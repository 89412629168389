import AuthService from '@/services/authService'
import SystemConfigService from '@/services/SystemConfigService'
import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const cacheGlobalMarket = localStorage.getItem(AppConfig.key.GlobalMarketKey)
const globalMarket = JSON.parse(cacheGlobalMarket) || null
console.log('globalMarket', globalMarket)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isDataLoading: false,
  userProfile: {
    fullname: '',
    username: ''
  },
  marketGroups: [],
  marketPayrates: [],
  markets: [],
  globalMarket: globalMarket || {
    groupId: null,
    marketId: null,
    round: {
      roundId: null,
      roundDate: null
    }
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  updateLoadingStatus(state, isLoading) {
    state.isDataLoading = isLoading
  },
  updateProfile (state, profile) {
    state.userProfile = profile
  },
  updateMarketGroup(state, groups) {
    state.marketGroups = groups

    if(!state.globalMarket.groupId) {
      const activeGroups = groups.filter(group=>group.status === 'Active')

      console.log('updateMarketGroup!', state.globalMarket.groupId, activeGroups, groups)
      if(activeGroups.length > 0) {
        state.globalMarket.groupId = activeGroups[0]._id
      }
    }
  },
  updateMarketPayrate(state, rates) {
    state.marketPayrates = rates
  },
  updateMarkets(state, markets) {
    state.markets = markets || []
  },
  updateGlobalMarket(state, opts) {
    console.log(opts)
    state.globalMarket = {
      ...state.globalMarket,
      ...opts
    }
    localStorage.setItem(AppConfig.key.GlobalMarketKey, JSON.stringify(state.globalMarket))
  }
}

const actions = {
  reloadProfile({ commit }) {
    AuthService.getProfile()
    .then((profile) => {
      commit('updateProfile', profile)
    })
    .catch((e) => {
      console.log(e)
      AuthService.logout()
    })
  },
  reloadMarketGroups({ commit }) {
    SystemConfigService.getGroups().then((response)=>{
      console.log(response)
      if(response.success) {
        const groups = response.data.filter((group)=>{
          return true //group.status === 'Active'
        })
        .map((group)=>{
          return {
            _id: group._id,
            groupTitle: group.groupTitle,
            status: group.status
          }
        })

        commit('updateMarketGroup', groups)
      }
    })
  },
  reloadMarketPayrates({ commit }) {
    SystemConfigService.getPayRates().then((response)=>{
      if(response.status === 'success') {
        const rates = response.data.map((rate)=>{
          return {
            _id: rate._id,
            rateTitle: rate.rateTitle
          }
        })

        commit('updateMarketPayrate', rates)
      }
    })
  },
  reloadMarkets({ commit }) {
    SystemConfigService.getMarkets().then((response)=>{
      if(response.success) {
        console.log(response)
        commit('updateMarkets', response.data)
      }
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
